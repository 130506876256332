import * as React from 'react';
import Layout from '../../components/Layout';

const Privacy = () => {
  return (
    <Layout>
      <h2>Your data so safe</h2>
    </Layout>
  );
};

export default Privacy;
